import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SacouponsSubscriptionService {

      // live
      baseUrl = 'https://livelife-admin-httpapi-host.azurewebsites.net/api/app/';
    // live - old
    // baseUrl = 'https://livelife-sacoupons-api.azurewebsites.net/api/subscriptions/';

    // staging
    //baseUrl = 'https://livelife-sacoupons-api-staging.azurewebsites.net/api/subscriptions/';

    // local
    // baseUrl = 'https://localhost:5001/api/subscriptions/';

    selectedCoupons: string[];

    constructor(
      private http: HttpClient) { }

    getSubscription(userId) {
      return this.http.get(`${this.baseUrl}s-aCoupons/subscription?tenantId=${environment.tenantId}&appUserId=${userId}`);
    };

    // gets the retailer list
    createSubscription(userId) {
      const id: number = userId;
      const createSubscription = {
        userId: id,
        msisdn: ''
      };

      console.log(createSubscription);
      return this.http.post(`${this.baseUrl}`, createSubscription);
    };
}
